@use 'assets/scss/colors';

.top-section {
	height: 100%;
	padding: 15px;
	white-space: nowrap;

	&__dropdown,
	&__radio,
	&__arrow {
		display: none;
	}

	&__live {
		margin-right: 5px;
		fill: colors.$icons-red;
	}

	&__container {
		position: relative;
		display: flex;
		height: 100%;
		align-items: center;
		color: colors.$text-primary;
		cursor: pointer;
	}
}

@media (min-width: 1025px) {
	.top-section {
		&:hover {
			.top-section__dropdown {
				display: flex;
			}
		}

		&__container {
			&:hover {
				color: colors.$text-link-active;
			}
		}
	}
}

@media (max-width: 1024px) {
	.top-section {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		padding: 0;

		&__arrow {
			display: block;
			fill: colors.$icons-primary;
		}

		&__radio {
			&:checked {
				& ~ .top-section__dropdown,
				& ~ slot > .top-section__dropdown {
					display: flex;
				}

				& ~ label .top-section__container {
					color: colors.$text-link-active;
				}

				& ~ label .top-section__arrow {
					fill: colors.$icons-active;
				}
			}
		}

		&__container {
			width: 100%;
			display: flex;
			justify-content: space-between;
			color: colors.$text-primary;
			padding: 15px;
			border-bottom: 1px solid colors.$line-line;

			&--with-subsections {
				pointer-events: none;
			}

			&--with-live-icon {
				justify-content: flex-start;
			}
		}

		&__dropdown {
			display: none;
		}
	}
}
